/**
 * @file
 * Styles for product categories and single product display.
*/

@import "variables";

//
// Products (/products)
//

// Product and Flag Categories Blocks, Related Items Block (On Product Node Display)
// Also THEME Block

#block-views-category-menu-block-cat-list,
#block-views-44820c96877701295df32ee65b9b78f0,
#block-views-cbc22f17d4aab7fe60540ad457726afa,
#block-views-theme-menu-block-thm-list,
#block-flagline-related-flagline-related {


  border-bottom: 5px solid #eee;
  margin: 0 20px 20px 0;
  display: block;
  padding-top: 13px;
  @include clearfix;

  .block-header { // Block Title
    h2 {
      text-align: center;
      color: $orange;
      padding: 5px 0 1px;
      background: #eee;
      margin: 0 20px 15px 0;
    }
  }


  @media only screen and (max-width: 767px) {
    margin-right: 0;
  }

  //$product_thumb_transition_duration: 0.5s;

  .views-row, .related-product-content {
    // 4 per row (DEFAULT - no mq)
    //height: 191px;
    width: 23%;
    float: left;
    margin: 0 1% 20px;
    //background: red;
    text-align: center;
    border: 1px solid #ccc;
    padding: 3px;
    @include box-shadow(0 0 0 rgba(black,0));
    /*@include transition(
      margin $product_thumb_transition_duration ease-in-out,
      width $product_thumb_transition_duration ease-in-out,
      height $product_thumb_transition_duration ease-in-out $product_thumb_transition_duration
    );*/


    &:hover {
      width: 24%;
      margin: -10px 0.5% 10px;
      @include box-shadow(0 0 5px rgba(black, 0.2));

      .views-field-field-image, .views-field-field-images {
        height: 130px;
      }
      span a {
        padding: 5px 15px;
      }
      .views-field-field-image, .views-field-field-images {
        a {
          height: 130px;
        }

      }
    }

    // 3 per row
    @media only screen and (max-width: 650px) {
      width: 31.3333333%;
      &:hover {
        width: 32.3333333%;
      }
    }

    // 2 per row
    @media only screen and (max-width: 500px) {
      width: 48%;
      &:hover {
        width: 49%;
      }
    }

    // 1 per row
    @media only screen and (max-width: 380px) {
      width: 100%;
      margin: 0 0 20px;
      &:hover {
        width: 100%;
      }
    }

    // 3 per row
    @media only screen and (min-width: 768px) and (max-width: 959px) {
      width: 31.3333333%;
      &:hover {
        width: 32.3333333%;
      }
    }

    // 5 per row
    @media only screen and (min-width: 1200px) {
      width: 18%;
      &:hover {
        width: 19%;
      }
    }

    // 6 per row
    @media only screen and (min-width: 1300px) {
      width: 14.6666666%;
      &:hover {
        width: 15.6666666%;
      }
    }

    // 7 per row
    @media only screen and (min-width: 1450px) {
      width: 13.2857142%;
      margin: 0 0.5% 20px;
      &:hover {
        width: 14.2857142%;
        margin: -10px 0% 10px;
      }
    }

    // 8 per row
    @media only screen and (min-width: 1600px) {
      width: 11.5%;
      &:hover {
        width: 12.5%;
      }
    }

    // 9 per row
    @media only screen and (min-width: 1750px) {
      width: 10.1111111%;
      &:hover {
        width: 11.1111111%;
      }
    }

    // 10 per row
    @media only screen and (min-width: 1900px) {
      width: 9%;
      &:hover {
        width: 10%;
      }
    }

    // 11 per row
    @media only screen and (min-width: 2050px) {
      width: 8.090909%;
      &:hover {
        width: 9.090909%;
      }
    }

    .views-field-name { // Theme Title
      background: #ddd;
      text-align: center;
      line-height: 1em;
      padding: 10px;
      .field-content {
        display: table-cell;
        vertical-align: middle;
        width: 500px;
        height: 36px;
      }
      a {
        color: $red-dark;
      }
    }

    .views-field-field-image, .views-field-field-images {
      height: 110px;
      //@include transition(
        //height $product_thumb_transition_duration ease-in-out
      //);

      .field-content {
        height: 110px;
      }

      a {
        display: block;
        height: 110px;
        //@include transition(
          //height $product_thumb_transition_duration ease-in-out
        //);
      }

    }

    img {
      margin: 0;
      padding: 5px;
      max-height: 100%;
      width: auto;
    }

    span a {
      display: block;
      background: #ddd;
      background: rgba(#ddd, 0.9);
      color: $red-dark;
      font-size: 1em;
      line-height: 1em;
      padding: 5px;
      display: table-cell;
      vertical-align: middle;
      height: 53px;
      position: relative;
      width: 1000px;
      @include transition(
        background 0.1s ease-in-out
      );

      &:hover {
        text-decoration: none;
      }

    }

  }
}

#block-views-cbc22f17d4aab7fe60540ad457726afa {
  margin-top: 30px;
  border-bottom: none;
}

//
// Related Products Block
//
#block-flagline-related-flagline-related {

  .block-header { // Block Title
    h2 {
      margin: 15px 0;
    }
  }

}

// Product and Flag Categories Blocks

/*#content.with-filters {
  #block-views-category-menu-block-cat-list, #block-views-44820c96877701295df32ee65b9b78f0 {
    .views-row {
      // 4 per row becomes 3 per row (DEFAULT)
      width: 31%;

      // 3 per row becomes 2 per row
      @media only screen and (min-width: 768px) and (max-width: 959px) {
        width: 48%;
        &:hover {
          width: 49%;
        }
      }

      // 5 per row becomes 4 per row
      @media only screen and (min-width: 1200px) {
        width: 23%;
        &:hover {
          width: 24%;
        }
      }

      // 6 per row becomes 5 per row
      @media only screen and (min-width: 1300px) {
        width: 18%;
        &:hover {
          width: 19%;
        }
      }

      // 7 per row becomes 6 per row
      @media only screen and (min-width: 1450px) {
        width: 14.6666666%;
        margin-left: 1%;
        margin-right: 1%;
        &:hover {
          width: 15.6666666%;
          margin-left: 0.5%;
          margin-right: 0.5%;
        }
      }

      // 8 per row becomes 7 per row
      @media only screen and (min-width: 1600px) {
        width: 13.2857142%;
        margin: 0 0.5% 20px;
        &:hover {
          width: 14.2857142%;
          margin: -10px 0% 10px;
        }
      }

      // 9 per row becomes 8 per row
      @media only screen and (min-width: 1750px) {
        width: 11.5%;
        &:hover {
          width: 12.5%;
        }
      }

      // 10 per row becomes 9 per row
      @media only screen and (min-width: 1900px) {
        width: 10.1111111%;
        &:hover {
          width: 11.1111111%;
        }
      }

      // 11 per row becomes 10 per row
      @media only screen and (min-width: 2050px) {
        width: 9%;
        &:hover {
          width: 10%;
        }
      }

    }
  }
}*/


//
// Product Categories (/products) AND No Results Block
//

#block-views-7767f4c4e57e3a50280d43f3adcda767, .view-display-id-block_prod_top {
    ul li {
    list-style: none;
    display: inline-block;
    float: left;
    width: 31%;
    margin: 0 1% 30px;

    @media only screen and (max-width: 1100px) {
      width: 48%;
    }
    @media only screen and (max-width: 767px) {
      width: 31%;
    }

    .image { // Image
      text-align: center;
    }

    .views-field-name { // Category Name
      font-family: $secondary-typeface;
      a {
        background: $red;
        display: block;
        @include border-radius(10px);
        color: white;
        padding: 4px 50px 1px 10px;
        font-size: 30px;
        line-height: 1em;
        margin-right: 5px;
        position: relative;
        &:hover {
          text-decoration: none;
          background: $red-dark;
          &:after {
            // :hover:after go here
          }
        }
        &:after {
          font-family: fontello;
          content: ">";
          color: white;
          background: $orange;
          height: 38px;
          width: 44px;
          @include border-radius(50%);
          position: absolute;
          right: 0;
          top: -9px;
          right: -9px;
          border: 4px solid white;
          padding: 9px 0px 0 2px;
          text-align: center;
        }
      }
    }

    .views-field-view { // HIDE Subcategories for now
      display: none;
    }

  }
}



//
// Product Sub Categories (/products/*)
//

// With Filters Applied, Products Per Row Changes
/*#content.with-filters {
  .node-product-display.view-mode-search_result, #block-views-category-menu-block-cat-list .views-row {
    width: 23%; // 5 across
    margin: 0 1% 30px;

    // 1 product per row
    @media only screen and (max-width: 385px) {
      width: 100%;
    }

    // 2 products per row
    @media only screen and (max-width: 514px) {
      width: 48%;
    }

    // 3 products per row
    @media only screen and (min-width: 515px) and (max-width: 766px) {
      width: 31.333333%;
    }

    // 1 products per row
    @media only screen and (min-width: 767px) and (max-width: 850px) {
      width: 98%;
    }

    // 2 products per row
    @media only screen and (min-width: 851px) and (max-width: 1100px) {
      width: 48%;
    }

    // 3 products per row
    @media only screen and (min-width: 1001px) and (max-width: 1100px) {
      width: 31.333333%;
    }

    // 4 products per row (DEFAULT - see above)

    // 5 products per row
    @media only screen and (min-width: 1650px) {
      width: 18%;
    }

    // 6 products per row
    @media only screen and (min-width: 1700px) {
      width: 14.666666%;
      margin-right: 1%; // Adjust margins for 7 products per row and greater
      margin-left: 1%;
    }

    // 7 products per row
    @media only screen and (min-width: 1850px) {
      width: 13.285714%;
      margin-right: 0.5%; // Adjust margins for 7 products per row and greater
      margin-left: 0.5%;
    }

    // 8 products per row
    @media only screen and (min-width: 2000px) {
      width: 11.5%;
    }

    // 9 products per row
    @media only screen and (min-width: 2200px) {
      width: 10.111111%;
    }
  }
}*/

.node-product-display.view-mode-search_result, .node-flag-display.view-mode-search_result, .node-apparel-display.view-mode-search_result {
  background: white !important;
  width: 18%; // 5 across
  margin: 0 1% 30px;
  float: left;
  //@include box-shadow(0 0 0 rgba(0,0,0,0.2));
  border: 1px solid white;
  position: relative;
  line-height: 0;
  padding-bottom: 3px;

  &:hover {
    z-index: 3;
    border: 1px solid $grey-light !important;
    //@include box-shadow(0 0 5px rgba(0, 0, 0, 0.2));
    //width: 19%;
    //margin: 0 0.5% 30px;
    .field-name-title {
    }

    .commerce-product-field-commerce-price {
      //background: $orange;
      //padding: 20px 10px 5px;
    }

    .field-type-commerce-product-reference, a.quicklook-link {
      display: block;
    }

    .field-type-commerce-product-reference { // Add to cart form
      @include box-shadow(0 1px 0 1px $grey-light);
      display: block;
      z-index: 3;

      form.commerce-add-to-cart.has-attributes {
        display: none; // hide Add to cart form if product has options
      }
    }

    /// Hover on Touch devices
    html.touch & {
      // Hide quicklook link
      a.quicklook-link {
        display: none;
      }
    }
  }

  /// Touch devices
  html.touch & {
    .field-type-commerce-product-reference {
      display: block !important;
      position: static !important;
      box-shadow: none !important;

      .attribute-widgets {
        display: none;
      }

      .form-item-quantity {
        display: none !important;
      }

      .form-submit {
        width: 100% !important;
      }
    }
    .quicklook-link {
      top: 100% !important;
      bottom: unset !important;
      width: 100% !important;
      margin: 0 !important;
    }
  }

  // 2 products per row
  @media only screen and (max-width: 514px) {
    width: 48%;

    .field-type-commerce-product-reference {
      display: block !important;
      position: static !important;
      box-shadow: none !important;

      .attribute-widgets {
        display: none;
      }

      .form-item-quantity {
        display: none !important;
      }

      .form-submit {
        width: 100% !important;
      }
    }

    .quicklook-link {
      top: 100% !important;
      bottom: unset !important;
      width: 100% !important;
      margin: 0 !important;
    }

    &:hover {
      //width: 49%;
    }
  }

  // 1 product per row
  @media only screen and (max-width: 385px) {
    width: 100%;
    margin: 0 0 30px;
    &:hover {
      //width: 100%;
      //margin: 0 0 30px;
    }
  }

  // 3 products per row
  @media only screen and (min-width: 515px) and (max-width: 1100px) {
    width: 31.333333%;
    &:hover {
      //width: 32.333333%;
    }
  }

  // 4 products per row
  @media only screen and (min-width: 1001px) and (max-width: 1100px) {
    width: 23%;
    &:hover {
      //width: 24%;
    }
  }

  // 5 products per row (DEFAULT - see above)

  // 6 products per row
  @media only screen and (min-width: 1650px) {
    width: 14.666666%;
    &:hover {
      //width: 15.666666%;
    }
  }

  // 7 products per row
  @media only screen and (min-width: 1700px) {
    width: 13.285714%;
    margin-right: 0.5%; // Adjust margins for 7 products per row and greater
    margin-left: 0.5%;
    &:hover {
      //width: 14.285714%;
      //margin-left: 0;
      //margin-right: 0;
    }
  }

  // 8 products per row
  @media only screen and (min-width: 1850px) {
    width: 11.5%;
    &:hover {
      //width: 12.5%;
    }
  }

  // 9 products per row
  @media only screen and (min-width: 2000px) {
    width: 10.1111111%;
    &:hover {
      //width: 11.1111111%;
    }
  }

  // 10 products per row
  @media only screen and (min-width: 2200px) {
    width: 9%;
    &:hover {
      //width: 10%;
    }
  }

  .field-type-commerce-product-reference { // Add to cart form
    background: white;
    display: inline-block;
    width: 100%;
    line-height: 1.6;
    //text-align: center;
    padding: 10px 5px 0;
    border: 3px solid white;
    border-top: none;
    z-index: -1;
    //bottom: 0;
    display: none;
    position: absolute;
    //@include box-shadow(0 0 0 1px $grey-light);
    @include transition(
      all 0.1s ease-in-out 0.3s
    );

    .form-item-quantity {
      display: inline-block;
      margin: 0 0 5px;
      line-height: 1em;
      position: relative;
      top: -1px;
      float: left;

      width: 25%;
      padding: 2px 0px;

      label { // Quantity
        display:none;
      }

      input {
        font-size: 16px;
        text-align: center;
        width: 30px;
        height: 26px;
      }

      .commerce-quantity-plusminus-link { // Plus & Minus
        a {
          display: inline-block;
          @include border-radius(0 3px 3px 0);
          padding: 6px 7px;
          margin: 0;
          &:hover {
            background: darken($red-dark, 10%);
            color: white;
          }
        }
      }

      .commerce-quantity-plusminus-link-decrease { // MINUS (-)
        a {
          @include border-radius(3px 0 0 3px);
        }
      }

      .commerce-quantity-plusminus-link-increase { // PLUS (+)
        a {

        }
      }
    }
    .form-submit { // Add to Cart Button
      background: darken($orange, 5%);
      margin: 0 0 5px;
      font-size: 18px;
      display: inline-block;
      line-height: 1em;
      padding: 5px 0;
      width: 75%;
      outline: none;
      //@include text-shadow(2px 2px 2px rgba(black,0.2));

      @include transition (
        background 0.1s linear
      );
      &:hover {
        background: lighten($orange, 5%);
        //@include text-shadow(1px 1px 5px rgba(black, 0.6));
      }
    }
  }

  .commerce-product-field-field-images { // Images (just one!)
    padding: 5px;
    text-align: center;
    //line-height: 0;
    height: 215px;
    //overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    position: relative;
    z-index: 2;
    //@include transition(
      //all 0.1s ease-in-out
    //);
    .field-item {
      display: none;
    }
    .field-item:first-of-type { //only show first image
      display:block;
    }
    img {
      position: relative;
      top: 0;
      margin: 5px 0 0;
      max-height: 200px; //100%;
      width: auto;
    }
  }

  a.quicklook-link { // Commerce Quicklook Link
    display: none;
    position: absolute;
    z-index: 999;
    bottom: 0px;
    width: 90%;
    margin: 0px 5%;
    text-align: center;
    background-color: #023B72;//#fff;
    border-radius: 2px;
    border: 1px solid #023B72;
    line-height: 1.5em;
    color: #FFF;//#D99901;

    &:hover {
      text-decoration: none;
    }

    & ~ .ajax-progress {
      position: absolute;
      right: 10px;
      bottom: 2px;
      z-index: 1000;
    }
  }

  .field-name-title  { // Product Title
    clear: both;
    //font-family: $secondary-typeface;
    background: white;
    text-align: center;
    padding: 5px 5px 0px 5px;
    //height: 64px;
    overflow: hidden;
    //font-size: 18px;
    line-height: 1em;
    height: 68px;
    //display: table-cell;
    vertical-align: middle;
    width: 100%;
    border: 3px solid white;
    border-bottom: none;
    position: relative;
    z-index: 2;
    margin-bottom: 5px;
    //@include transition(
      //all 0.1s ease-in-out
    //);
    h2 {
      font-size: 14px;
      font-family: $primary-typeface;
      line-height: 1.25em;
    }
    a {
      color: $grey;
    }
  }

  .commerce-product-field-commerce-price { // Price
    position: relative;
    z-index: 4;
    line-height: 1em;
    padding: 5px 10px;
    font-weight: bold;
    background: white;
    text-align: center;

    .field-label, .field-items {
      float: none;
      display: inline-block;
    }
  }

  .field-type-commerce-product-reference {

    input[type="submit"] { // Add To Cart Button
      width: 75%;
      display: inline-block;
      outline: none;
    }

    input[type="hidden"] + input[type="submit"] {
      width: 100%; // Full width when out of stock
    }

  }

}

//
// Related Categories Block
//

#block-facetapi-4zh0ggjmbgeeybrlpjce8eml3a6ple0j--2, #block-facetapi-hg3wmtxbqdlmem5j1trqwokhhgmxy1xw--2, #block-facetapi-6rl94kyr8rhfidmzzq9crph5gdjmqmdr--2 {
  clear: left;
  display: inline-block;
  width: 100%;
  //border: 2px solid lighten($red-dark, 60%);
  margin-bottom: 35px;

  .block-header { // Block Title
    h2 {
      text-align: center;
      color: $red-dark;
      padding: 5px 0 1px;
      background: #eee;
      margin-bottom: 15px;
      margin-top: 0;
    }
  }

  ul {
    li.parent {
      display: inline;
      list-style: none;
      //color: white;
      > a.facetapi-active {
        color: transparent;
        background: $grey-light;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        display: inline-block;
        position: relative;
        top: 1px;
        margin-right: 5px;
        &:before {
          position: absolute;
          top: 3px;
          left: 3px;
          font-family: fontello;
          content: "x";
          color: white;
          line-height: 1em;
          font-size: 10px;
        }

        &:hover {
          text-decoration: none;
          background: $red-dark;
        }

      }
      > .facet-term-image {
        display: none;
      }
      &:before {
        //content: "[-]";
      }
    }
    li.child {
      list-style: none;
      @include box-shadow(0 0 9px rgba(0,0,0,0.2));
      background: white;
      border: 1px solid $grey-light;
      width: 23%;
      margin: 0 1% 30px;
      float: left;

      @media only screen and (max-width: 1235px) {
        width: 31%;
      }
      @media only screen and (max-width: 870px) {
        width: 48%;
      }
      @media only screen and (max-width: 767px) {
        width: 31%;
      }
      @media only screen and (max-width: 560px) {
        width: 48%;
      }
      @media only screen and (max-width: 380px) {
        width: 100%;
      }

      a {
        background: $red-dark;
        color: white;
        text-align: center;
        display: block;
        line-height: 2.5em;
        &:hover {
          background: lighten($red-dark, 15%);
          text-decoration: none;
        }
      }

    }

    .facet-term-image { // Image
      background: white;
      text-align: center;
      padding: 10px 0;
      display: table-cell;
      width: 500px;
      vertical-align: middle;
      height: 140px;
      line-height: 0;
      img {
        margin-bottom: 0;
      }
    }
  }
}

//
// Related Themes Block
//

#block-facetapi-hg3wmtxbqdlmem5j1trqwokhhgmxy1xw--2 {
  .block-header { // Block Title
    h2 {
      color: $orange;
    }
  }
  ul {
    li.parent > a.facetapi-active {
      &:hover {
        background: $orange;
      }
    }
    li.child a {
      background: $orange;
      &:hover {
        background: lighten($orange, 15%);
      }
    }
  }
}

//
// Related Geography Block
//

#block-facetapi-6rl94kyr8rhfidmzzq9crph5gdjmqmdr--2 {
  .block-header { // Block Title
    h2 {
      color: $blue-dark;
    }
  }
  ul {
    li.parent > a.facetapi-active {
      &:hover {
        background: $blue-dark;
      }
    }
    li.child a {
      background: $blue-dark;
      &:hover {
        background: lighten($blue-dark, 15%);
      }
    }
  }
}
